import React from "react"
import Layout from "../components/Layout"
import About from "../components/About"
import "../styles/about.css"
const AboutPage = () => {
  return (
    <>
      <Layout
        title="About | Rayane Silva"
        description="More information about my journey as developer."
      >
        <section className="about-page">
          <About />
        </section>
      </Layout>
    </>
  )
}

export default AboutPage
