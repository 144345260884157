export default [
  {
    id: 1,
    title: "React",
  },
  {
    id: 2,
    title: "Javascript",
  },
  {
    id: 3,
    title: "Node.js",
  },
  {
    id: 4,
    title: "PostgreSQL",
  },
  {
    id: 5,
    title: "GraphQL",
  },
]
