import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import SecondaryButton from "./SecondaryButton"
import stack from "../constants/stack"
import "../styles/about.css"
import "../styles/main.css"

const About = () => {
  return (
    <section className="about__section">
      <div className="about__img">
        <StaticImage
          src="../assets/profile-photo.jpeg"
          alt="Rayane's profile picture"
          className="about__img--profile"
          loading="eager"
        />
      </div>
      <article className="about__text">
        <div className="underline"></div>
        <h1>
          How I got <span>here</span>
        </h1>
        <div className="about__text--body">
          <p>
            My name is Rayane and I am a full stack developer, exploring a new
            side of the world. I grew up in São Paulo, Brazil and currently
            reside in Limburg, Belgium.
          </p>
          <p>
            I graduated from the University of São Paulo with a degree in
            History. I have 8 years of experience in designing and maintaining
            organizational systems to keep track of archive materials. I've been
            a developer since 2021, when I reviewed my career prospects and
            decided to participate in the Full Stack Development Course of
            HackYourFutureBelgium, where I recently graduated.
          </p>
          <p>
            It was the first time I'd ever programmed and I struggled with the
            content a lot at first. But during this course I gained some
            knowledge on web development, open source projects, system design
            and agile work principles.
          </p>
          <p>
            When I'm not on my computer, I'm probably traveling, watching movies
            or reading books on diverse subjects.
          </p>
        </div>
        <div className="about__stack">
          <h2>
            My tech <span className="title__bg--yellow">stack</span>
          </h2>
          <div className="about__stack--item">
            {stack.map(item => (
              <span key={item.id}>{item.title}</span>
            ))}
          </div>
        </div>

        <div className="about__btn">
          <SecondaryButton
            buttonText="See resume"
            url="/resume"
            aria-label="my resume button"
          ></SecondaryButton>
        </div>
      </article>
    </section>
  )
}

export default About
